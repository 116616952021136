import { toast } from 'react-toastify'
import axios from '../../../axios/axios'


export async function retrieveExcel(
    year?: string,
) {
    return await axios
        .get('/registrations/export' + (year ? '?year=' + year : ''), {
            responseType: 'blob',
        })
}

export async function confirmRegistrationUser(
    year: string | number,
    userId: string,
) {
    const requestBody = {
        status: 'CONFIRMED',
    }
    await axios
        .patch(`/registrations/validate/${userId}/${year}`, requestBody)
        .then((res) => {
            console.log(res.data)
            toast.success('Confirmation effectuée')
        })
        .catch((e) => toast.error('Erreur lors de la confirmation'))
}

export async function exportExcelFile(
    setModalActive: (state: boolean) => void,
    year?: string,
) {
    try {
        const response = await retrieveExcel(year)
        const contentDisposition = response.headers['content-disposition']
        const fileName = contentDisposition
            ? contentDisposition.split('filename=')[1].replace(/"/g, '')
            : `participant.xlsx`
        
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()

        link.remove()
        window.URL.revokeObjectURL(url)
    } catch (error) {
        toast.error('Erreur lors du téléchargement du fichier')
    }
    setModalActive(false)
}
