import axios from '../../axios/axios'
import { useEffect, useState } from 'react'
import NavbarAdmin from '../../components/NavbarAdmin'

import './adminEtablissement.css'
import AdminQuestion from '../../components/questions/AdminQuestion'
import { useParams } from 'react-router-dom'
import IQuestion from '../../types/IQuestion'

export interface QuestionSimpleI {
    id?: string
    title?: string
    text?: string
    image_start?: string
    image_end?: string
    isMultiple?: boolean
    askTotal?: boolean
    openAnswer: number
    position?: number
    coefficient?: number
    possibleAnswers?: {
        id?: string
        value: string
        isCorrect: boolean
    }[]
}

function AdminCreateAndEditQuizz() {
    const { id } = useParams()
    const [modalContents, setModalContents] = useState<JSX.Element>(<></>)

    const [questions, setQuestions] = useState<IQuestion[]>([])
    useEffect(() => {
        resetData()
    }, [id])

    function resetData() {
        axios.get(`questionnaire/${id}`).then((res) => {
            setQuestions(res.data.questions)
        })
    }

    function setQuestion(questionId: number, question: IQuestion) {
        const newQ = questions
        newQ[questionId] = question
        setQuestions((val) => [...newQ])
    }

    function save() {
        // For each question :
        // If their is an ID : If it is not the same as Original : Update
        // If no ID : POST

        questions.map((question, index) => {
            if (question?.id) {
                if (question.title) {
                    const ques = { ...question } as QuestionSimpleI
                    delete ques?.possibleAnswers
                    axios.patch(`question/${question?.id}`, { ...ques })
                    question?.possibleAnswers &&
                    question?.possibleAnswers?.forEach((ans) => {
                        if (ans.id) {
                            if (ans.value == '') {
                                axios.delete(`question-answer/${ans?.id}`)
                            } else {
                                axios.patch(`question-answer/${ans?.id}`, {
                                    ...ans,
                                })
                            }
                        } else {
                            axios.post(`question-answer/${question?.id}`, {
                                ...ans,
                            })
                        }
                    })
                } else {
                    axios.delete(`question/${question?.id}`)
                }
            } else {
                if (question.title) {
                    question.possibleAnswers.filter((val) => val.value != '')
                    axios
                        .post(`question/${id}`, { ...question })
                        .then((res) => console.log(res.data))
                        .catch((err) => console.log(err))
                }
            }
        })
        setTimeout(() => resetData(), 1000)
    }

    function addQuestion() {
        const newQ = [
            ...questions,
            {
                title: undefined,
                text: undefined,
                openAnswer: 0,
                isMultiple: false,
                askTotal: false,
                coefficient: 0,
                position: questions.length + 1,
                possibleAnswers: [
                    {
                        value: 'exemple',
                        isCorrect: true,
                    },
                ],
            },
        ]

        setQuestions(newQ)
    }

    return (
        <>
            <div className="admin-container">
                <div className="navbar">
                    <NavbarAdmin active="questionnaires" />
                </div>

                <div className="page-body">
                    <div
                        style={{
                            fontStyle: 'italic',
                            fontSize: 10,
                            textAlign: 'center',
                            marginBottom: 10,
                        }}
                    >
                        Toute question n'ayant pas de titre sera suprimée lors
                        de l'enregistrement.
                        <br />
                        Toute réponse vide sera suprimée lors de
                        l'enregistrement.
                        <br />
                        Si une personne a déjà répondu au questionnaire, il ne
                        sera pas possible d'enregistrer le questionnaire
                        <br />
                        Pour les entrée RegeX :{' '}
                        <a
                            href="https://regex-generator.olafneumann.org/"
                            target="_blank"
                        >
                            https://regex-generator.olafneumann.org/
                        </a>{' '}
                        Cocher "Match Whole Line" dans les options (3)
                        <br />
                        <br />
                        <button
                            className={'buttons-blue blue-admin'}
                            onClick={() => save()}
                        >
                            Enregistrer
                        </button>
                    </div>

                    {questions.map((question, index) => (
                        <AdminQuestion
                            key={question?.id || index}
                            subkey={index}
                            id={question?.id}
                            title={question?.title}
                            text={question?.text}
                            image_start={question?.image_start}
                            image_end={question?.image_end}
                            isMultiple={question?.isMultiple}
                            askTotal={question?.askTotal}
                            regex={question?.regex}
                            openAnswer={question?.openAnswer}
                            possibleAnswers={question.possibleAnswers}
                            position={question?.position}
                            coefficient={question?.coefficient}
                            setQuestion={(newquestion) => {
                                setQuestion(index, newquestion)
                            }}
                        />
                    ))}
                    <button
                        className={'buttons-blue blue-admin'}
                        onClick={() => addQuestion()}
                    >
                        Ajouter une question
                    </button>
                </div>
            </div>
        </>
    )
}

export default AdminCreateAndEditQuizz
