export enum eroles {
    USER = 'user',
    CLUB = 'club',
    ADMIN = 'admin',
    ESTABLISHEMENT = 'establishment',
}

export enum eestablishements {
    COLLEGE = 'Collège',
    ECOLE = 'École',
    LYCEE = 'Lycée',
    SUP = 'Supérieur',
}

export enum ecategories {
    CE = 'CE',
    CM = 'CM',
    C1 = 'C1',
    C2 = 'C2',
    L1 = 'L1',
    L2 = 'L2',
    LE_L2_EN_EQUIPE = 'LE - L2 en équipe et individuel',
    GP = 'GP',
    HC = 'HC',
}

export enum elangs {
    FRANCAIS = 'Français',
    ANGLAIS = 'Anglais',
    ITALIEN = 'Italien',
    POLONAIS = 'Polonais',
    RUSSE = 'Russe',
    UKRAINIEN = 'Ukrainien',
    ARABE = 'Arabe',
    ALLEMAND = 'Allemand',
}

export enum eStatus {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    DESACTIVATED = 'DESACTIVATED',
}

export enum eConfig {
    ACTIVE_YEAR = 'ACTIVE_YEAR',
    ACTIVE_REGISTRATION_INDIVIDUAL = 'ACTIVE_REGISTRATION_INDIVIDUAL',
    ACTIVE_REGISTRATION_STUDENT = 'ACTIVE_REGISTRATION_STUDENT',
    ACTIVE_REGISTRATION_SCHOOL = 'ACTIVE_REGISTRATION_SCHOOL',
    ACTIVE_REGISTRATION_CLUB = 'ACTIVE_REGISTRATION_CLUB',
    ACTIVE_REGISTRATION_CENTER = 'ACTIVE_REGISTRATION_CENTER',
}
