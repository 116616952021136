import Header from '../../components/Header'
import MadeByJisep from '../../components/MadeByJisep'

type ParticipantLayoutProps = {
    children: string | JSX.Element | JSX.Element[]
    headerButtons?: {
        title: string
        link?: string
        action?: () => void
        loadingId?: string
        active?: boolean
    }[]
    color: 'yellow' | 'orange' | 'red'
    noDisconnectButton?: boolean
}
export default function ParticipantLayout({
                                              children,
                                              headerButtons,
                                              color,
                                              noDisconnectButton,
                                          }: ParticipantLayoutProps) {
    return (
        <div
            style={{
                width: '100%',
                paddingInline: 10,
                maxWidth: '1400px',
                minWidth: '70vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: '100vh',
            }}
        >
            <Header
                buttons={headerButtons}
                color={color}
                noDisconnectButton={noDisconnectButton}
            />
            <div style={{ display: 'flex', flexGrow: 1 }}>{children}</div>
            <div
                className="footer"
                style={{
                    // height: 30,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: '100%',
                    padding: 5,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <div id="contact">
                        <div className="contact-contain">
                            <h1 id="contact-title">Contact</h1>
                            <a id="contact-mail" href="mailto:contact@ffjm.org">
                                contact@ffjm.org
                            </a>
                        </div>
                    </div>
                </div>

                <div
                    className="version"
                    style={{
                        opacity: 0.5,
                        display: 'flex',
                        alignItems: 'flex-end',
                        flexDirection: 'column',
                        padding: 10,
                    }}
                >
                    <div>Version : {process.env.REACT_APP_VERSION || 'dev-version'}</div>
                    <MadeByJisep />
                </div>
            </div>
        </div>
    )
}
