import './table.css'
import ConfirmIcon from './utils/icons/ConfirmIcon'
import DeleteIcon from './utils/icons/DeleteIcon'
import EditIcon from './utils/icons/EditIcon'
import QualifyIcon from './utils/icons/QualifyIcon'

export type TableStyle = {
    name: string
    isSortable: boolean
    filters: string[] | null
    index: string
    secondIndex?: string
}

export type PageObject = {
    totalItems?: number
    pages: number
    actual: number
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ActionsType = {
    [key: string]: {
        showCondition?: (arg0: any) => boolean
        action: (arg0: any) => void
    }
}

/* eslint-enable @typescript-eslint/no-explicit-any */

function Table(props: {
    showActions?: boolean
    titles: TableStyle[]
    data: object[] | null
    color: string
    pagination: (arg0: number) => void
    filter: (arg0: string, arg1: string) => void
    actions: ActionsType
    page: PageObject
    margin: string
    showDelete?: boolean
    showModify?: boolean
    showConfirm?: boolean
    showQualify?: boolean
    noDelete?: boolean
    noConfirm?: boolean
    noModify?: boolean
    noQualify?: boolean
}) {
    function generateArray(
        borneSuperieure: number,
        valeurDeDepart: number,
    ): number[] {
        if (props.page.pages == 1) return [1]

        const gauche = Math.max(valeurDeDepart - 2, 1)
        const droite = Math.min(valeurDeDepart + 2, borneSuperieure)

        const result: number[] = []
        for (let i = gauche; i <= droite; i++) {
            result.push(i)
        }

        return result
    }

    const showList = generateArray(props.page.pages, props.page.actual)

    return (
        <>
            <div id="Page-table">
                <table>
                    <thead>
                    <tr>
                        {(props.actions.confirm ||
                                props.actions.modify ||
                                props.actions.delete ||
                                props.actions.qualify) &&
                            props?.showActions !== false && (
                                <td
                                    key="actions"
                                    style={{
                                        backgroundColor: `var(${props.color})`,
                                    }}
                                >
                                    Actions
                                </td>
                            )}
                        {props.titles.map((title, index) => {
                            if (title.isSortable) {
                                return (
                                    <td
                                        key={index}
                                        className="sortable"
                                        style={{
                                            backgroundColor: `var(${props.color})`,
                                        }}
                                    >
                                        {title.name}
                                    </td>
                                )
                            } else {
                                return (
                                    <td
                                        key={index}
                                        style={{
                                            backgroundColor: `var(${props.color})`,
                                        }}
                                    >
                                        {title.name}
                                    </td>
                                )
                            }
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {props.data ? (
                        //eslint-disable-next-line
                        props.data.map((row: any, index) => (
                            <tr key={index}>
                                {props?.showActions !== false &&
                                    Object.keys(props?.actions).length >
                                    0 && (
                                        <td
                                            className="flex"
                                            style={{
                                                gap: 2,
                                                flexWrap: 'wrap',
                                                maxWidth: 70,
                                                justifyContent:
                                                    'space-evenly',
                                            }}
                                        >
                                            {Object.keys(
                                                props?.actions,
                                            ).map((action) => {
                                                if (
                                                    !props.actions[action]
                                                        ?.showCondition ||
                                                    props?.actions[
                                                        action
                                                        ].showCondition?.(row)
                                                ) {
                                                    return (
                                                        <div
                                                            className="pointer"
                                                            key={
                                                                index +
                                                                action
                                                            }
                                                        >
                                                            {action ===
                                                                'modify' && (
                                                                    <EditIcon
                                                                        action={() =>
                                                                            props?.actions[
                                                                                action
                                                                                ].action(
                                                                                row,
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            {action ===
                                                                'delete' && (
                                                                    <DeleteIcon
                                                                        action={() =>
                                                                            props?.actions[
                                                                                action
                                                                                ].action(
                                                                                row,
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            {action ===
                                                                'confirm' && (
                                                                    <ConfirmIcon
                                                                        action={() =>
                                                                            props?.actions[
                                                                                action
                                                                                ].action(
                                                                                row,
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            {action ===
                                                                'qualify' && (
                                                                    <QualifyIcon
                                                                        action={() =>
                                                                            props?.actions[
                                                                                action
                                                                                ].action(
                                                                                row,
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                        </div>
                                                    )
                                                }
                                                return <></>
                                            })}
                                        </td>
                                    )}

                                {props.titles.map((title, columnIndex) => {
                                    if (
                                        title.index == 'results.qualified'
                                    ) {
                                        const value =
                                            row.results &&
                                            row.results.length > 0 &&
                                            row.results[0].qualified
                                                ? 'Oui'
                                                : 'Non'
                                        return (
                                            <td key={columnIndex}>
                                                {value}
                                            </td>
                                        )
                                    } else if (title.index.includes('.')) {
                                        let indexList =
                                            title.index.split('.')

                                        let value = ''
                                        if (indexList?.[2]?.length > 0) {
                                            value =
                                                row[
                                                    indexList[0] as keyof typeof row
                                                    ]?.[indexList[1]]?.[
                                                    indexList[2]
                                                    ]
                                        } else {
                                            value =
                                                row[
                                                    indexList[0] as keyof typeof row
                                                    ]?.[indexList[1]]
                                        }
                                        if (!value && title.secondIndex)
                                            indexList =
                                                title.secondIndex.split('.')

                                        if (indexList?.[2]?.length > 0) {
                                            value =
                                                row[
                                                    indexList[0] as keyof typeof row
                                                    ]?.[indexList[1]]?.[
                                                    indexList[2]
                                                    ]
                                        } else {
                                            value =
                                                row[
                                                    indexList[0] as keyof typeof row
                                                    ]?.[indexList[1]]
                                        }
                                        if (!value) value = '-'

                                        return (
                                            <td key={columnIndex}>
                                                {value ?? '-'}
                                            </td>
                                        )
                                    } else {
                                        let val = ''
                                        if (title.index == 'status') {
                                            if (
                                                row[
                                                    title.index as keyof typeof row
                                                    ] == 'CONFIRMED' ||
                                                row[
                                                    title.index as keyof typeof row
                                                    ] == 'A payé'
                                            ) {
                                                val = 'Confirmé'
                                            } else {
                                                val = 'En attente'
                                            }
                                            return (
                                                <td key={columnIndex}>
                                                    {val}
                                                </td>
                                            )
                                        } else if (
                                            title.index == 'answers'
                                        ) {
                                            return (
                                                <td key={columnIndex}>
                                                    {row.answers[0].answers.join(
                                                        ', ',
                                                    )}
                                                </td>
                                            )
                                        } else {
                                            const value =
                                                row[
                                                    title.index as keyof typeof row
                                                    ] || '-'
                                            if (typeof row[title.index as keyof typeof row] === 'boolean') {
                                                return (
                                                    <td key={columnIndex}>
                                                        {row[title.index as keyof typeof row] ? 'Oui' : '-'}
                                                    </td>
                                                )
                                            }
                                            return (
                                                <td key={columnIndex}>
                                                    {value}
                                                </td>
                                            )
                                        }
                                    }
                                })}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={props.titles.length + 1}>
                                Aucun résultat
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <div id="pagination">
                {!showList.includes(1) ? (
                    <>
                        <span
                            className="page-select"
                            style={{
                                borderColor: `var(${props.color})`,
                                color: `var(${props.color})`,
                            }}
                            onClick={() => props.pagination(1)}
                        >
                            {props.page.pages === 0 ? 0 : 1}
                        </span>
                        <div
                            id="three-dots"
                            style={{ color: `var(${props.color})` }}
                        >
                            ...
                        </div>
                    </>
                ) : null}
                {showList.map((page, index) => {
                    if (page == props.page.actual) {
                        return (
                            <span
                                id="page-select-actual"
                                style={{
                                    borderColor: `var(${props.color})`,
                                    backgroundColor: `var(${props.color})`,
                                }}
                                key={index}
                            >
                                {page}
                            </span>
                        )
                    } else {
                        return (
                            <span
                                className="page-select"
                                style={{
                                    borderColor: `var(${props.color})`,
                                    color: `var(${props.color})`,
                                }}
                                key={index}
                                onClick={() => props.pagination(page)}
                            >
                                {page}
                            </span>
                        )
                    }
                })}
                {!showList.includes(props.page.pages) ? (
                    <>
                        <div
                            id="three-dots"
                            style={{ color: `var(${props.color})` }}
                        >
                            ...
                        </div>
                        <span
                            className="page-select"
                            style={{
                                borderColor: `var(${props.color})`,
                                color: `var(${props.color})`,
                            }}
                            onClick={() => props.pagination(props.page.pages)}
                        >
                            {props.page.pages}
                        </span>
                    </>
                ) : null}
            </div>
        </>
    )
}

export default Table
