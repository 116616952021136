function ChoiceAccountForm({
                               individuel,
                               scolaire,
                               etablissment,
                               club,
                           }: {
    individuel: () => void
    scolaire: () => void
    etablissment: () => void
    club: () => void
}) {
    return (
        <div
            style={{
                justifyContent: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                gap: 10,
                paddingTop: '10%',
            }}
        >
            <button
                className="buttons-blue buttons-blue-insc tooltip"
                onClick={() => individuel()}
            >
                Participant individuel
                <span className="tooltiptext">
                    Participant à titre individuel, hors du cadre scolaire.
                </span>
            </button>
            <button
                className="buttons-blue buttons-blue-insc tooltip"
                onClick={() => scolaire()}
            >
                Participant scolaire
                <span className="tooltiptext">
                    Élève qualifié, ayant passé les quarts de finale dans son
                    établissement scolaire.
                </span>
            </button>
            <button
                className="buttons-blue buttons-blue-insc tooltip"
                onClick={() => etablissment()}
            >
                Établissement scolaire
                <span className="tooltiptext">
                    Établissement, représenté par un enseignant, organisant les
                    quarts de finale qualificatifs pour ses élèves.
                </span>
            </button>
            <button
                className="buttons-blue buttons-blue-insc tooltip"
                onClick={() => club()}
            >
                Représentant de club
                <span className="tooltiptext">Représentant de Club</span>
            </button>
        </div>
    )
}

export default ChoiceAccountForm
